import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Masonry from 'react-masonry-css'
import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  700: 2,
  500: 1
};

export default function Landscape({ data }) {

  useEffect(() => {
    const boxes = gsap.utils.toArray('.event-item');
    boxes.forEach((box, i) => {
      const anim = gsap.fromTo(box, {
          autoAlpha: 0,
          y: 50,
          stagger: 0.20,
          ease: "power1.in"
        }, {
          duration: 1,
          autoAlpha: 1,
          y: 0
      });
      ScrollTrigger.create({
        trigger: box,
        animation: anim,
        toggleActions: 'play none none none',
      });
    });
  }, []);
  
  return (
    <Layout>
      <Seo title="Chad Rieder | Minneapolis Event Photographer" description="Chad Rieder is a Minneapolis, MN based event photographer. If interested in working with him, please contact for availability and event photography pricing." />
      <div className="grid-items">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid-col"
        >
           
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1539800289/event/hyundai-genesis-nfl-experience.jpg"
              alt="Hyundai Genesis NFL"
              placeholder="blurred"
            />
            <span id="caption">Hyundai Genesis NFL event</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534693980/event/jameson-whisky-party.jpg"
              alt="Jameson Whisky"
              placeholder="blurred"
            />
            <span id="caption">Jameson Whisky bartender's ball</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534693977/event/andrew-zimmern-minneapolis.jpg"
              alt="Andrew Zimmern"
              placeholder="blurred"
            />
            <span id="caption">Andrew Zimmern chef event</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534694165/event/target-hearth-hand-opening.jpg"
              alt="Target product opening"
              placeholder="blurred"
            />
            <span id="caption">Target product grand opening</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1543160599/event/mn-runs-turkey-trot.jpg"
              alt="MN runs"
              placeholder="blurred"
            />
            <span id="caption">MN Runs Turkey Trot</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534693983/event/nfl-youth-football-camp.jpg"
              alt="Hyundai football camp"
              placeholder="blurred"
            />
            <span id="caption">Hyundai NFL youth football camp</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534693974/event/hyundai-nfl-experience.jpg"
              alt="Hyundai NFL experience"
              placeholder="blurred"
            />
            <span id="caption">Hyundai NFL experience</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534693971/event/craig-morgan-target-event.jpg"
              alt="Craig Morgan"
              placeholder="blurred"
            />
            <span id="caption">Craig Morgan at Target event</span>
          </div>
          <div className="masonry-item event-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534693974/event/greg-jennings-nfl.jpg"
              alt="Greg Jennings"
              placeholder="blurred"
            />
            <span id="caption">Hyundai Genesis NFL meet & greet</span>
          </div>

        </Masonry>
          
      </div>

    </Layout>
  )
}
